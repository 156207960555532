import React, { useState, useEffect } from "react";
import D1 from "./assets/D1.jpg";
import D2 from "./assets/D2.jpg";
import D3 from "./assets/D3.png";
import D4 from "./assets/D4.jpg";
import D5 from "./assets/D5.jpg";
import D6 from "./assets/D6.jpg";
import D7 from "./assets/D7.jpg";
import D8 from "./assets/D8.jpg";
import D9 from "./assets/D9.jpg";
import D10 from "./assets/D10.jpg";
import D11 from "./assets/D11.jpg";
import D12 from "./assets/D12.jpg";
import D13 from "./assets/D13.jpg";
import D14 from "./assets/D14.jpg";
import D15 from "./assets/d15.jpg";
import D16 from "./assets/D16.jpg";
import D17 from "./assets/d17.jpg";
import D18 from "./assets/D18.jpg";
import D19 from "./assets/D19.png";
import D20 from "./assets/D20.png";
import D21 from "./assets/D21.jpg";
import D22 from "./assets/D22.jpg";
import D23 from "./assets/D23.jpg";
import D24 from "./assets/D24.jpg";
import D25 from "./assets/d25.jpg";
import D26 from "./assets/D26.jpg";
import D27 from "./assets/D27.jpg";
import D28 from "./assets/D28.jpg";
import D29 from "./assets/D29.jpg";
import D30 from "./assets/D30.jpg";
import D31 from "./assets/D31.jpg";
import D32 from "./assets/D32.jpg";
import D33 from "./assets/D33.jpg";
import D34 from "./assets/D34.jpg";
import D35 from "./assets/D35.jpg";
import D36 from "./assets/D36.jpg";
import D37 from "./assets/D37.jpg";
import D39 from "./assets/D39.jpg";
import D40 from "./assets/D40.jpg";
import D41 from "./assets/D41.jpg";
import D42 from "./assets/D42.jpg";
import D43 from "./assets/D43.jpg";

const daphPost = [
  {
    img: D43,
    date: "09/1/23",
    description: "passed OUT",
  },
  {
    img: D42,
    date: "08/31/23",
    description: "Throwback 3",
  },
  {
    img: D41,
    date: "08/30/23",
    description: "Throwback 2",
  },
  {
    img: D40,
    date: "08/29/23",
    description: "Throwback",
  },
  {
    img: D39,
    date: "08/28/23",
    description: "No place like the couch.",
  },
  {
    img: D37,
    date: "08/27/23",
    description: "Fort Daphne!",
  },
  {
    img: D36,
    date: "08/26/23",
    description: "Thats my toy, punk!",
  },
  {
    img: D35,
    date: "08/25/23",
    description: "Aw. With her baby blanket!",
  },
  {
    img: D34,
    date: "08/24/23",
    description: "Chillin!",
  },
  {
    img: D33,
    date: "08/23/23",
    description: "Toy LIfe",
  },
  {
    img: D32,
    date: "08/22/23",
    description: "Off for a Ride - Part 2",
  },
  {
    img: D31,
    date: "08/21/23",
    description: "Off for a Ride - Part 1",
  },
  {
    img: D30,
    date: "08/20/23",
    description: "Ready for my closeup",
  },
  {
    img: D29,
    date: "08/19/23",
    description: "Sunspot",
  },
  {
    img: D28,
    date: "08/18/23",
    description: "Perfect temp",
  },
  {
    img: D27,
    date: "08/17/23",
    description: "Where are the treats",
  },
  {
    img: D26,
    date: "08/16/23",
    description: "00-Daphne",
  },
  {
    img: D25,
    date: "08/15/23",
    description: "Slap the chewy",
  },
  {
    img: D24,
    date: "08/14/23",
    description: "HAIRCUT. I feel so much better!",
  },
  {
    img: D23,
    date: "08/13/23",
    description: "Long day",
  },
  {
    img: D22,
    date: "08/12/23",
    description: "Couch life",
  },
  {
    img: D21,
    date: "08/11/23",
    description: "I like this table better",
  },
  {
    img: D20,
    date: "08/10/23",
    description: "Guard Doggie",
  },
  {
    img: D19,
    date: "08/09/23",
    description: "High ground!",
  },
  {
    img: D18,
    date: "08/08/23",
    description: "Who's a little...",
  },
  {
    img: D17,
    date: "08/07/23",
    description: "Keeping Loose",
  },
  {
    img: D16,
    date: "08/06/23",
    description: "Protector of toys",
  },
  {
    img: D15,
    date: "08/05/23",
    description: "Throwback",
  },
  {
    img: D14,
    date: "08/04/23",
    description: "Electric Pupper",
  },
  {
    img: D13,
    date: "08/03/23",
    description: "PLAY TIME!",
  },

  {
    img: D10,
    date: "08/02/23",
    description: "Hey",
  },
  {
    img: D11,
    date: "08/01/23",
    description: "Lazy Days of Summer",
  },
  {
    img: D12,
    date: "??/??/??",
    description: "AWWWWeeEEEeeeE",
  },
  {
    img: D4,
    date: "??/??/??",
    description: "Little T-O-Y Action",
  },
  {
    img: D3,
    date: "??/??/??",
    description: "Post Op Puppy Surgery...",
  },
  {
    img: D1,
    date: "??/??/??",
    description: "Younger Pups Rule",
  },
  {
    img: D2,
    date: "??/??/??",
    description: "After a Hard Day",
  },

  {
    img: D5,
    date: "??/??/??",
    description: "Sooooo Sleepy",
  },
  {
    img: D6,
    date: "??/??/??",
    description: "Look At the Little Baby Doggie",
  },
  {
    img: D7,
    date: "??/??/??",
    description: "Comfortable",
  },
  {
    img: D8,
    date: "??/??/??",
    description: "You Talking to Me?",
  },
  {
    img: D9,
    date: "??/??/??",
    description: "Sleepy Time",
  },

  // Add more data as needed
];

const PostComponent = ({ img, date, description, onClick, isClicked }) => {
  return (
    <div className="mb-10 flex flex-col border-2 border-slate-100 rounded-xl bg-pink-600 shadow-2xl">
      <div className="rounded-md shadow-xl imggame">
        <img
          src={img}
          alt=""
          className={`w-full h-full object-cover rounded-t ${
            isClicked ? "centered-image clicked" : ""
          }`}
          onClick={onClick}
        />
      </div>
      <div className="ml-4 mr-4 flex flex-col flex-1 justify-between">
        <div className="py-3">
          <p className="text-slate-200 text-sm">{date}</p>
          <p className="text-slate-50 text-lg">{description}</p>
        </div>
      </div>
    </div>
  );
};

const PostsList = ({ posts }) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);

  const handleImageClick = (index) => {
    if (index === clickedImageIndex) {
      setClickedImageIndex(null); // Reset clicked image
    } else {
      setClickedImageIndex(index);
    }
  };

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth >= 1537) {
        setItemsPerPage(10);
      } else if (window.innerWidth >= 1024) {
        setItemsPerPage(10);
      } else if (window.innerWidth >= 768) {
        setItemsPerPage(10);
      } else {
        setItemsPerPage(25);
      }
    };

    updateItemsPerPage();
    const handleResize = () => updateItemsPerPage();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalPages = Math.ceil(posts.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage((prevPage) => Math.min(prevPage, totalPages));
  }, [itemsPerPage, totalPages]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, posts.length);
  const visiblePosts = posts.slice(startIndex, endIndex);

  return (
    <div>
      <div className="flex justify-center py-8 xl:py-10">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`bg-pink-600 hover:opacity-70 text-white font-semibold py-2 px-4 rounded mx-1 ${
              currentPage === page ? "bg-opacity-60" : ""
            }`}
          >
            {page}
          </button>
        ))}
      </div>
      <div className="pb-8 px-4 md:px-8 xl:px-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-5 3xl:grid-cols-6 gap-4 xl:gap-10">
        {visiblePosts.map((post, index) => (
          <PostComponent
            key={index}
            img={post.img}
            date={post.date}
            description={post.description}
            onClick={() => handleImageClick(index)}
            isClicked={index === clickedImageIndex}
          />
        ))}
      </div>
    </div>
  );
};

const MainComponent = () => {
  return (
    <div>
      <PostsList posts={daphPost} />
    </div>
  );
};

export default MainComponent;
