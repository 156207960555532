import "./footer.css";

function Footer() {
  return (
    <div className="bg-fuchsia-950 text-white py-4 text-center">
      <p className="txt-sm font-md">&#169;DaphneADay.com</p>
      <p>Contact: herbswebdev@gmail.com</p>
    </div>
  );
}
export default Footer;
