import Stuff from "./Navbar";
import HeroSec from "./Hero";
import MainComponent from "./Pics";
import Footer from "./Footer";

function App() {
  return (
    <div className="bg-gray-200">
      <Stuff></Stuff>
      <HeroSec></HeroSec>
      <MainComponent></MainComponent>
      <Footer></Footer>
    </div>
  );
}

export default App;
